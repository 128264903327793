.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.neue-regular {
  font-family: "Neue-regular";
}

.Neue-bold {
  font-family: "Neue-bold";
}

@font-face {
  font-family: "PPNeueMontreal-Light";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Light.ttf");
}
@font-face {
  font-family: "Neue-light";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Light.ttf");
}
@font-face {
  font-family: "Neue-regular";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Regular.ttf");
}
@font-face {
  font-family: "Neue-bold";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Bold.ttf");
}
@font-face {
  font-family: "Neue-montreal-medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.otf");
}
@font-face {
  font-family: "Neue-montreal-regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "Canela-RegularItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-RegularItalic-Trial.otf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "CanelaDeck-LightItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-LightItalic-Trial.otf");
}
@font-face {
  font-family: "ppneuemontreal-thin";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Thin.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.ttf");
}
.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #001316;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

.form--section--get--started {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 28.75rem;
  margin: 2.75rem auto;
  position: relative;
}
.form--section--get--started .custom__search {
  position: relative;
  width: 100%;
}
.form--section--get--started .custom__search .custom__search__input {
  width: 100%;
  border: 1px solid #75ffff;
  border-radius: 100px;
  padding: 0.938rem 6.25rem 0.938rem 1.25rem;
  line-height: 1;
  box-sizing: border-box;
  color: #fffaf7;
  outline: none;
  font-size: 1rem;
  background: #111b1d;
}
.form--section--get--started .custom__search .custom__search__botton {
  font-family: "neuePowerTrial-Regular";
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 1rem;
  bottom: 5px;
  border: 0;
  background: #75ffff;
  color: #001316;
  outline: none;
  margin: 0;
  padding: 0.25rem 2.125rem;
  border-radius: 100px;
  z-index: 2;
}
.form--section--get--started .custom__search p.font-12 {
  font-family: "neueMontreal-Thin";
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  left: 1rem;
  bottom: -2.25rem;
  position: absolute;
  letter-spacing: 0.5px;
}
.form--section--get--started .custom__search .referal-code-part {
  position: relative;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part {
  display: flex;
  position: absolute;
  align-items: center;
  bottom: -5rem;
  left: 1.2rem;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  color: #fffaf7;
  font-family: "neueMontreal-Thin";
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 0.875rem;
  text-align: left;
  display: flex;
  align-items: center;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  padding-right: 8px;
  line-height: 36px;
  font-size: 0.75rem;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #75f7ff;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #75f7ff;
  border-color: #75f7ff;
  animation: wave-4 0.4s ease;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part {
  display: block;
  display: flex;
  align-items: center;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design {
  display: flex;
  align-items: center;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design span {
  color: #a6a6a6;
  font-size: 0.875rem;
  font-family: "neueMontreal-Regular";
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  position: absolute;
  z-index: 1;
  top: 11px;
  padding-left: 5px;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design input {
  position: relative;
  max-width: 6rem;
  background: #001316;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  border: 0.3px solid rgba(111, 111, 111, 0.5803921569);
  height: 24px;
  border-radius: 4px;
  color: #fffaf7;
  font-size: 0.75rem;
  font-family: "neueMontreal-Regular";
  line-height: 1rem;
  font-weight: 400;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design input:focus-visible {
  outline: 1px solid #75ffff;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design button {
  display: none;
  background: #75ffff;
  padding: 0.35rem 0.875rem 0.5rem 0.875rem;
  color: #001316;
  font-family: "neuePowerTrial-Medium";
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  text-align: center;
  border: 1px solid #75ffff;
  border-radius: 100px;
  margin-left: 1rem;
}
.form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design .green-tick {
  height: 100%;
  margin-left: 0.75rem;
  display: none;
}
.form--section--get--started .custom__search .referal-code-part .warning-msg {
  display: none;
  position: absolute;
  bottom: -5.7rem;
  left: 10.3rem;
}
.form--section--get--started .custom__search .referal-code-part .warning-msg p {
  color: #e50914 !important;
  text-align: center;
  font-size: 0.75rem !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-top: 0;
  line-height: 1rem;
}

@media (max-width: 900px) {
  .form--section--get--started {
    margin-top: 10.5rem;
  }
  .form--section--get--started .custom__search .custom__search__input {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  .form--section--get--started .custom__search .custom__search__botton {
    font-size: 0.75rem;
    font-family: "neuePowerTrial-Regular";
  }
  .form--section--get--started .custom__search .referal-code-part {
    bottom: 0rem;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part {
    align-items: flex-start;
    bottom: -7rem;
    display: flex;
    flex-direction: row !important;
    height: 4.625rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx {
    font-size: 0.625rem;
    padding: 0.375rem 0rem 0.375rem 1rem;
    margin: 0px;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part {
    display: block;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design {
    align-items: center;
    display: flex;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part .code-part-design span {
    top: 30px;
  }
  .form--section--get--started .custom__search .referal-code-part .referal-code-design-part .code-part input {
    width: 5rem;
    font-size: 0.75rem;
  }
  .form--section--get--started .custom__search .referal-code-part .warning-msg {
    display: none;
    bottom: -6.525rem;
    left: 9rem;
  }
  .form--section--get--started .custom__search .referal-code-part .warning-msg p {
    font-size: 0.6rem !important;
    padding-right: 0;
  }
  .form--section--get--started .custom__search p.font-12.mt-1 {
    font-family: "neueMontreal-Regular";
    text-align: left;
    padding: 0;
  }
  .error-box {
    bottom: -1rem !important;
    font-size: 0.655rem !important;
    left: 19px;
    max-width: 11.125rem !important;
  }
}/*# sourceMappingURL=getstarted-form.css.map */
